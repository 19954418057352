import { acceptHMRUpdate, defineStore } from 'pinia';
import { currentCompanyCookie, formatPhone, logError } from '@/common/lib';
import { apolloClient } from '@/common/apollo.js';
import { GetCurrentCompanyForAdmin } from '@/common/queries/company.query.graphql';

export const useRootStore = defineStore('rootStore', {
    state: () => ({
        ready: false,
        user: null,
        currentCompany: null,
        lastProjectId: null,
        entrypointIntegrityHashes: {
            user: null,
        },
    }),

    getters: {
        loggedIn: (state) => {
            if (!state.ready) {
                return false;
            }

            return !!state.user;
        },

        hasRole (state) {
            return (role) => {
                if (!this.loggedIn) {
                    return false;
                }

                // all logged in users have ROLE_USER
                if (role === 'ROLE_USER') {
                    return true;
                }

                if (state.user.roles === null) {
                    return false;
                }

                return state.user.roles.includes(role);
            };
        },
    },

    actions: {
        ready () {
            this.ready = true;
        },
        updateUser (user) {
            if (user.phoneNumber) {
                user.phoneNumber = formatPhone(user.phoneNumber);
            }
            if (user.companies) {
                user.companies = user.companies.map((uc) => ({
                    ...uc.company,
                    userCompanyRole: uc.userCompanyRole,
                }));
            }

            if (this.user === null) {
                this.user = { ...user };
            } else {
                this.user = { ...this.user, ...user };
            }
        },
        async setDefaultCompany () {
            if (this.hasRole('ROLE_EXTERNAL') || this.hasRole('ROLE_WORDPRESS')) {
                if (window.location.pathname !== '/no-companies') {
                    window.location.href = '/no-companies';
                }

                return;
            }

            // admins don't have companies assigned directly to them
            if (this.hasRole('ROLE_ADMIN')) {
                await this.setDefaultCompanyForAdmin();

                return;
            }

            const cookieCompanyId = currentCompanyCookie.get();
            if (cookieCompanyId) {
                // only set it if it's one of the companies they currently have access to
                this.user.companies.forEach((company) => {
                    if (company.companyId === cookieCompanyId) {
                        this.setCurrentCompany(company);
                    }
                });
            }

            if (!this.currentCompany) {
                if (this.user.companies.length > 1) {
                    this.setCurrentCompany('ALL');
                } else {
                    this.setCurrentCompany(this.user.companies[0]);
                }
            }
        },
        async setDefaultCompanyForAdmin () {
            const cookieCompanyId = currentCompanyCookie.get();
            if (cookieCompanyId) {
                try {
                    // retrieve the company data
                    const { data: { Company } } = await apolloClient.query({
                        query: GetCurrentCompanyForAdmin,
                        variables: { companyId: cookieCompanyId },
                    });
                    if (Company) {
                        this.setCurrentCompany(Company);
                    }
                } catch (e) {
                    logError(e);
                }
            }

            if (!this.currentCompany) {
                this.setCurrentCompany('ALL');
            }
        },
        setCurrentCompany (company) {
            this.currentCompany = company;

            currentCompanyCookie.set(company);
        },
        setLastProjectId (projectId) {
            this.lastProjectId = projectId;
        },
        resetLastProjectId () {
            this.lastProjectId = null;
        },

        setIntegrityHash ({ entrypoint, hash }) {
            if (this.entrypointIntegrityHashes[entrypoint]) {
                // eslint-disable-next-line no-console
                console.error('Integrity hash already set for '+entrypoint+' entry point. Won\'t update.');
                return;
            }

            this.entrypointIntegrityHashes[entrypoint] = hash
        },
    },
});

if (import.meta.hot) {
    import.meta.hot.accept(acceptHMRUpdate(useRootStore, import.meta.hot))
}
